import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { IGenericResponse } from '../models/Schemas'
import { Observable, of , throwError} from 'rxjs';
import { MainService } from './main.service';


@Injectable({
  providedIn: 'root'
})
export class UtilsService extends MainService {
 
  constructor(http:HttpClient) {
    super(http)
   }

 

  getLists(lists: string[]): Observable<IGenericResponse> {    
    var data = {
      lists
    }
    return this.post('utils/getLists', data);
  }

}
